import { createI18n } from "vue-i18n";
import messagesDE from "./translations/de.json";

export const getMessages = () => {
  return {
    "de-DE": messagesDE
  };
};

const i18n = new createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: "de-DE", // set locale
  fallbackLocale: "de-DE", // set fallback locale
  messages: getMessages(), // set locale messages
  globalInjection: true,

});

export default i18n;
