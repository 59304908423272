<template>
  <TransitionRoot as="template" :show="dialogOpen">
    <Dialog as="div" static class="fixed z-50 inset-0 overflow-y-auto" @close="closeEvent" :open="dialogOpen">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <input type="button" style="width: 0; height: 0" aria-hidden autofocus>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                         leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300"
                         enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                         enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                         leave-from="opacity-100 translate-y-0 sm:scale-100"
                         leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
              class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 w-80 lg:w-1/2">
            <div>
              <div class="mt-3 text-center sm:mt-5">

                <DialogTitle as="div" class="text-lg leading-6 font-semibold text-gray-900">
                  {{ title }}
                </DialogTitle>
                <div class="mt-2">
                  <p v-if="!subMarkdown" class="text-sm leading-5 font-normal text-gray-900">
                    {{ subLine }}
                  </p>
                  <Markdown
                      v-else
                      id="markdown"
                      :html="true"
                      class="text-sm leading-5 font-normal text-gray-900"
                      :source="subLine"
                      :plugins="[
                        {
                          plugin: makeLinksAbsolute
                        },
                  {
                    plugin: MarkdownItLinkAttributes,
                    options: { attrs: { target: '_blank' } },
                  },
                ]"
                  />
                </div>
              </div>
            </div>
            <div
                class="mt-5 sm:mt-6 flex flex-col"
                :class="{
                  'lg:flex-row-reverse': secondaryButtonLabel !== null,
                  'lg:flex-row justify-center': secondaryButtonLabel === null,
                }">
              <slot name="button">
                <div class="w-full lg:w-1/2 ml-0 mb-2 lg:ml-2 lg:mb-0">
                  <ibiola-button-primary
                      v-if="!isLoading"
                      :label="primaryButtonLabel"
                      @click="primaryClicked"
                  ></ibiola-button-primary>
                  <div v-else>
                  <ibiola-loading-spinner ></ibiola-loading-spinner>
                  </div>
                </div>
                <div
                    v-if="secondaryButtonLabel !== null"
                    class="w-full lg:w-1/2 mr-0 lg:mr-2">
                  <ibiola-button-secondary

                      :label="secondaryButtonLabel"
                      @click="secondaryClicked">
                  </ibiola-button-secondary>
                </div>
              </slot>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {onMounted, onUnmounted, ref, watch} from 'vue'
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import IbiolaButtonPrimary from "@/components/Buttons/ButtonPrimary";
import IbiolaButtonSecondary from "@/components/Buttons/ButtonSecondary";
import IbiolaLoadingSpinner from "@/components/Layouts/LoadingSpinner.vue";
import Markdown from "vue3-markdown-it";
import MarkdownItLinkAttributes from "markdown-it-link-attributes";
import useMarkdown from "@/composables/Tools/useMarkdown";

export default {
  name: "ibiola-dialog",
  components: {
    Markdown,
    IbiolaLoadingSpinner,
    IbiolaButtonSecondary,
    IbiolaButtonPrimary,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    title: {
      required: true,
      type: String
    },
    subLine: {
      required: true,
      type: String
    },
    open: {
      required: false,
      type: Boolean,
      default: false,
    },
    primaryButtonLabel: {
      required: false,
      type: [String, Number]
    },
    secondaryButtonLabel: {
      required: false,
      type: [String, Number],
      default: null
    },
    allowClose: {
      required: false,
      type: Boolean,
      default: true,
    },
    isLoading: {
      required: false,
      type: Boolean,
      default: false,
    },
    subMarkdown: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'opened',
    'closed',
    'primary',
    'secondary',
  ],
  setup(props, {emit}) {
    const dialogOpen = ref(false);

    onMounted(() => {
      dialogOpen.value = props.open;

      const style = document.createElement('style');
      style.textContent = `
    #markdown a {
      text-decoration: underline;
      color: #2D8CF0;
    }
    #markdown a:hover {
      text-decoration: none;
    }
  `;
      style.id = 'dynamic-link-styles';
      document.head.append(style);
    });

    onUnmounted(() => {

      // Remove the dynamically added styles
      const styleElement = document.getElementById('dynamic-link-styles');
      if (styleElement) {
        styleElement.remove();
      }
    });


    watch(() => props.open, (value) => {
      dialogOpen.value = value;
      // console.log("value",value);
      if (value) {
        emit('opened');
      } else {
        emit('closed');
      }
    });

    const {makeLinksAbsolute} = useMarkdown();

    const closeEvent = () => {
      if(props.allowClose){
        dialogOpen.value = false;
        emit('closed')
      }
    }

    const primaryClicked = () => {
      emit('primary');
      // dialogOpen.value = false;
    };
    const secondaryClicked = () => {
      emit('secondary');
      // dialogOpen.value = false;
    };

    return {
      dialogOpen,
      closeEvent,
      primaryClicked,
      secondaryClicked,
      makeLinksAbsolute,
      MarkdownItLinkAttributes
    }
  },
}
</script>

<style scoped>

</style>
