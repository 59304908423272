
import { library } from '@fortawesome/fontawesome-svg-core'

import { faHome as farHome } from '@fortawesome/pro-regular-svg-icons/faHome'
library.add(farHome)

import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome'
library.add(faHome)

import { faSpinnerThird as farSpinnerThird } from '@fortawesome/pro-regular-svg-icons/faSpinnerThird'
library.add(farSpinnerThird)

import { faBriefcase } from '@fortawesome/pro-solid-svg-icons/faBriefcase'
library.add(faBriefcase)

import { faCarWrench } from '@fortawesome/pro-solid-svg-icons/faCarWrench'
library.add(faCarWrench)

import { faHouse } from '@fortawesome/pro-solid-svg-icons/faHouse'
library.add(faHouse)

import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock'
library.add(faClock)

import { faCarBurst } from "@fortawesome/pro-solid-svg-icons/faCarBurst";
library.add(faCarBurst);

import { faCarRear } from "@fortawesome/pro-solid-svg-icons/faCarRear";
library.add(faCarRear);

import { faCarOn } from "@fortawesome/pro-solid-svg-icons/faCarOn";
library.add(faCarOn);

import { faCarSide } from "@fortawesome/pro-solid-svg-icons/faCarSide";
library.add(faCarSide);

import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan'
library.add(faBan)

import { faBan as falBan } from '@fortawesome/pro-light-svg-icons/faBan'
library.add(falBan)

import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons/faLockKeyhole'
library.add(faLockKeyhole)

import { faLockKeyholeOpen } from '@fortawesome/pro-solid-svg-icons/faLockKeyholeOpen'
library.add(faLockKeyholeOpen)

import { faMapLocationDot } from '@fortawesome/pro-solid-svg-icons/faMapLocationDot'
library.add(faMapLocationDot)

import { faMap } from '@fortawesome/pro-solid-svg-icons/faMap'
library.add(faMap)

import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft'
library.add(faArrowLeft)

import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'
library.add(faStar)

import { faStar as farStar } from '@fortawesome/pro-regular-svg-icons/faStar'
library.add(farStar)

import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
library.add(faCircleExclamation)

import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
library.add(faChevronLeft)

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
library.add(faChevronRight)

import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
library.add(faChevronDown)

import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons/faCalendarDays'
library.add(faCalendarDays)

import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlass'
library.add(faMagnifyingGlass)

import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark'
library.add(faCircleXmark)

import { faCircleStop } from '@fortawesome/pro-solid-svg-icons/faCircleStop'
library.add(faCircleStop)

import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons/faCircleInfo'
library.add(faCircleInfo)

import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation'
library.add(faTriangleExclamation)

import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
library.add(faPencil)

import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
library.add(faPhone)

import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
library.add(faEnvelope)

import { faEnvelopeDot } from '@fortawesome/pro-solid-svg-icons/faEnvelopeDot'
library.add(faEnvelopeDot)

import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
library.add(faLink)

import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
library.add(faUsers)

import { faGasPump } from '@fortawesome/pro-solid-svg-icons/faGasPump'
library.add(faGasPump)

import { faChargingStation } from '@fortawesome/pro-solid-svg-icons/faChargingStation'
library.add(faChargingStation)

import { faGears } from '@fortawesome/pro-solid-svg-icons/faGears'
library.add(faGears)

import { faCircleQuestion as farCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faCircleQuestion'
library.add(farCircleQuestion)

import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons/faCircleQuestion'
library.add(faCircleQuestion)

import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion'
library.add(faQuestion)

import { faLocationArrow as farLocationArrow } from '@fortawesome/pro-regular-svg-icons/faLocationArrow'
library.add(farLocationArrow)

import { faUser as farUser } from '@fortawesome/pro-regular-svg-icons/faUser'
library.add(farUser)

import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
library.add(faUser)

import { faRectangleList as farRectangleList } from '@fortawesome/pro-regular-svg-icons/faRectangleList'
library.add(farRectangleList)

import { faWifiSlash } from '@fortawesome/pro-solid-svg-icons/faWifiSlash'
library.add(faWifiSlash)

import { faBicycle as faBicycle } from '@fortawesome/pro-regular-svg-icons/faBicycle'
library.add(faBicycle)

import { faBicycle as farBicycle } from '@fortawesome/pro-solid-svg-icons/faBicycle'
library.add(farBicycle)

import { faPersonWalking} from '@fortawesome/pro-solid-svg-icons/faPersonWalking'
library.add(faPersonWalking)

import { faCar as faCar } from '@fortawesome/pro-regular-svg-icons/faCar'
library.add(faCar)

import { faCar as farCar } from '@fortawesome/pro-solid-svg-icons/faCar'
library.add(farCar)

import { faBus as faBus } from '@fortawesome/pro-solid-svg-icons/faBus'
library.add(faBus)

import { faCars as farCars } from '@fortawesome/pro-regular-svg-icons/faCars'
library.add(farCars)

import { faCarMechanic } from '@fortawesome/pro-solid-svg-icons/faCarMechanic'
library.add(faCarMechanic)

import { faTruck } from '@fortawesome/pro-solid-svg-icons/faTruck'
library.add(faTruck)

import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'
library.add(faXmark)

import { faArrowDownToBracket } from '@fortawesome/pro-solid-svg-icons/faArrowDownToBracket'
library.add(faArrowDownToBracket)

import { faLock as farLock } from '@fortawesome/pro-regular-svg-icons/faLock'
library.add(farLock)

import { faUserCircle as farUserCircle } from '@fortawesome/pro-regular-svg-icons/faUserCircle'
library.add(farUserCircle)

import { faIdCard as farIdCard } from '@fortawesome/pro-regular-svg-icons/faIdCard'
library.add(farIdCard)

import { faCreditCard as farCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard'
library.add(farCreditCard)

import { faBookOpen as farBookOpen } from '@fortawesome/pro-regular-svg-icons/faBookOpen'
library.add(farBookOpen)

import { faTags as farTags } from '@fortawesome/pro-regular-svg-icons/faTags'
library.add(farTags)

import { faGlobe as farGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe'
library.add(farGlobe)

import { faComment as farComment } from '@fortawesome/pro-regular-svg-icons/faComment'
library.add(farComment)

import { faBluetooth } from '@fortawesome/pro-solid-svg-icons/faBluetooth'
library.add(faBluetooth)

import { faBluetooth as farBluetooth } from '@fortawesome/pro-regular-svg-icons/faBluetooth'
library.add(farBluetooth)

import { faBluetooth as falBluetooth } from '@fortawesome/pro-light-svg-icons/faBluetooth'
library.add(falBluetooth)

import { faCloud as falCloud } from '@fortawesome/pro-light-svg-icons/faCloud'
library.add(falCloud)

import { faLocationCircle as farLocationCircle } from '@fortawesome/pro-regular-svg-icons/faLocationCircle'
library.add(farLocationCircle)

import { faLocationDot } from '@fortawesome/pro-solid-svg-icons/faLocationDot'
library.add(faLocationDot)

import { faUserSlash as farUserSlash } from '@fortawesome/pro-regular-svg-icons/faUserSlash'
library.add(farUserSlash)

import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut'
library.add(faSignOut)

import { faPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff'
library.add(faPowerOff)

import { faFaceSadSweat } from '@fortawesome/pro-solid-svg-icons/faFaceSadSweat'
library.add(faFaceSadSweat)

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare'
library.add(faArrowUpRightFromSquare)

import { faPartyHorn } from '@fortawesome/pro-solid-svg-icons/faPartyHorn'
library.add(faPartyHorn)

import { faCars } from '@fortawesome/pro-solid-svg-icons/faCars'
library.add(faCars)

import { faCalendarPlus } from '@fortawesome/pro-solid-svg-icons/faCalendarPlus'
library.add(faCalendarPlus)

import { faPlusMinus } from '@fortawesome/pro-solid-svg-icons/faPlusMinus'
library.add(faPlusMinus)

import { faCalendarXmark } from '@fortawesome/pro-solid-svg-icons/faCalendarXmark'
library.add(faCalendarXmark)

import { faStopwatch } from '@fortawesome/pro-solid-svg-icons/faStopwatch'
library.add(faStopwatch)

import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons/faMoneyBill'
library.add(faMoneyBill)

import { faRoad } from '@fortawesome/pro-solid-svg-icons/faRoad'
library.add(faRoad)

import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
library.add(faCircleCheck)

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
library.add(faCheck)

import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter'
library.add(faFilter)

import { faReceipt } from '@fortawesome/pro-solid-svg-icons/faReceipt'
library.add(faReceipt)

import { faFileImage } from '@fortawesome/pro-solid-svg-icons/faFileImage'
library.add(faFileImage)

import { faFilePdf } from '@fortawesome/pro-solid-svg-icons/faFilePdf'
library.add(faFilePdf)

import { faFile } from '@fortawesome/pro-solid-svg-icons/faFile'
library.add(faFile)

import { faFileLines } from '@fortawesome/pro-solid-svg-icons/faFileLines'
library.add(faFileLines)

import { faFilePlus } from '@fortawesome/pro-solid-svg-icons/faFilePlus'
library.add(faFilePlus)

import { faTrash } from '@fortawesome/pro-solid-svg-icons/faTrash'
library.add(faTrash)

import { faCarBuilding } from '@fortawesome/pro-solid-svg-icons/faCarBuilding'
library.add(faCarBuilding)

import { faBell } from "@fortawesome/pro-solid-svg-icons/faBell";
library.add(faBell);

import { faMegaphone } from "@fortawesome/pro-solid-svg-icons/faMegaphone";
library.add(faMegaphone);

import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons/faEllipsisVertical";
library.add(faEllipsisVertical);

import { faCoins } from "@fortawesome/pro-solid-svg-icons/faCoins";
library.add(faCoins);

import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons/faArrowsRotate";
library.add(faArrowsRotate);

import { faClipboard } from "@fortawesome/pro-solid-svg-icons/faClipboard";
library.add(faClipboard);
