<template>
  <button
    :id="id"
    :type="type"
    :disabled="disabled"
    @click="buttonClicked"
    v-bind:class="buttonClasses"
  >
    <span class="flex flex-row space-x-2 justify-items-center items-center">
      <span v-if="hasPreIcon">
        <slot name="pre-icon"></slot>
      </span>
      <span v-if="hasLabel">
        {{ label }}
      </span>
      <span v-if="hasPostIcon">
        <slot name="post-icon"></slot>
      </span>
    </span>
  </button>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";

export default {
  name: "ibiola-button-secondary",
  components: {},
  props: {
    id: {
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    size: {
      required: false,
      type: String,
      default: "normal" /* possible values: xs|normal|xl */,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    type: {
      required: false,
      type: String,
      default: "button" /* possible values: button|submit|reset */,
    },
    autofocus: {
      required: false,
      type: Boolean,
      default: false,
    },
    widthFull: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  emits: ["click"],
  setup(props, { emit, slots }) {
    const hasPreIcon = ref(false);
    const hasPostIcon = ref(false);

    if (slots["pre-icon"] && slots["pre-icon"]().length) {
      hasPreIcon.value = true;
    }
    if (slots["post-icon"] && slots["post-icon"]().length) {
      hasPostIcon.value = true;
    }

    const hasLabel = computed(() => {
      let label = false;
      if (props.label && props.label.length > 0) {
        label = true;
      }
      return label;
    });

    const buttonClasses = computed(() => {
      return {
        "items-center w-full flex flex-row justify-center bg-primary-50 text-primary-500 border border-primary-50 rounded-md": true, // Default
        "hover:border hover:border-primary-300": true, // Hover
        "focus:ring-primary-500 focus:border focus:border-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2": true, // Focus
        "active:ring-0 active:bg-primary-500 active:border-0 active:text-gray-50": true, // Active
        "disabled:bg-gray-300 disabled:text-gray-500 disabled:border-0": true, // Disabled
        "h-button-xs padding-button-xs text-xs leading-4 font-semibold":
          props.size === "xs",
        "h-button-normal padding-button-normal text-sm leading-5 font-semibold":
          props.size === "normal",
        "h-button-xl padding-button-xl text-base leading-6 font-semibold":
          props.size === "xl",
        "w-full": props.widthFull === true,
      };
    });

    const buttonClicked = () => {
      emit("click");
    };

    return {
      hasPreIcon,
      hasPostIcon,
      buttonClasses,
      hasLabel,
      buttonClicked,
    };
  },
  methods: {},
};
</script>

<style scoped lang="css">
.padding-button-xs {
  padding: 8px 12px;
}

.padding-button-normal {
  padding: 16px 18px;
}

.padding-button-xl {
  padding: 18px 25px;
}
</style>
